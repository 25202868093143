<template>
  <div class="section">
    <p class="subtitle start">POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</p>
    <p class="subtitle">Fecha: 26.03.2025</p>

    <p>Te explicamos de manera simple y detallada los usos que le damos a tu información, cómo y por qué procesamos tus datos y cómo los resguardamos para garantizar que sean confidenciales y estén seguros.</p>

    <p class="subtitle">Formas en las que recogemos tu información:</p>

    <ul style="list-style-type:disc;">
      <li><p>Cuando te registras o das información utilizando nuestros canales App o página o sitio  Web PRESTIX.</p></li>

      <li><p>De fuentes confiables (proveedores de servicios, de burós de crédito y organismos públicos).</p></li>
    </ul>

    <div>
      <p class="subtitle">Cómo protegemos tus datos</p>

      <p>Protegemos tu información personal con los más altos estándares de seguridad. <b>No comercializamos tus datos.</b> Haciendo un uso responsable de la información personal, no sólo protegemos la privacidad de quienes nos confiaron sus datos, sino que les permitimos operar con seguridad y confianza con nuestros servicios.</p>

      <p>PRESTIX se especializa en procesar y analizar  grandes volúmenes de información, utilizando data no tradicional para construir un Score crediticio de los usuarios, brindando una solución para la inclusión financiera.</p>

      <p>Mediante la utilización de la ciencia de datos, métodos estadísticos y la elaboración de algoritmos basados en grafos, PRESTIX construye un modelo de evaluación de riesgo (scoring) que permite evaluar a más personas y  ampliar el acceso al mercado financiero.</p>

      <p>Al registrarte con tus datos personales, aceptás esta transferencia de datos, almacenamiento o procesamiento. PRESTIX se compromete a tomar todas las medidas necesarias para garantizar que sus datos sean tratados de forma segura y de acuerdo con los más altos estándares de seguridad.</p>

      <p>Para proteger la información de cualquier visualización, modificación, divulgación no autorizada, PRESTIX toma los siguientes recaudos:</p>

      <ul style="list-style-type:disc;">
        <li><p>Encriptación de los servicios mediante el protocolo SSL.</p></li>

        <li><p>Revisión de la política en materia de recolección, almacenamiento y tratamiento de datos, incluyendo las medidas de seguridad físicas, para impedir el acceso no autorizado a nuestros sistemas.</p></li>

        <li><p>Límite al acceso de los contratistas, los agentes y los empleados de PRESTIX a la información suministrada por el usuario a ser procesada asegurando el cumplimiento de estrictas obligaciones de confidencialidad establecidas contractualmente junto con eventuales consecuencias derivadas del incumplimiento de las mismas.</p></li>
      </ul>
    </div>

    <div>
      <p class="subtitle">Tu privacidad es muy importante para nosotros y nos esforzamos para protegerla.</p>

      <p>Con el  fin de poder brindarte el mejor servicio, tratamos, recolectamos y almacenamos información sobre las personas que, como vos, son usuarios y visitantes de los sitios web y/o aplicaciones móviles, conforme se detalla aquí (las "Plataformas" y los "Servicios", respectivamente).</p>

      <p>Esta Declaración de Privacidad y Confidencialidad de la Información (la "Declaración de Privacidad") describe la información que recolectamos y trata sobre vos y lo que puede hacer con ella.</p>

      <p><b>Prestar tu consentimiento voluntario, expreso e informado a esta Declaración de Privacidad es un requisito esencial para poder contratar y/o tener cualquier tipo de relación con la empresa.</b></p>
    </div>

    <div>
      <p class="subtitle">Información que nos proporcionas directamente al registrarte o utilizar nuestros servicios</p>

      <p>- Nombre, imagen personal (foto selfie),</p>

      <p>- Número de documento o identificación válida (foto del documento de identidad),</p>

      <p>- Información de contacto (como número de teléfono, domicilio, dirección de correo electrónico),</p>

      <p>- Información de ingresos, ocupación, actividad económica,</p>

      <p>- Datos de cuenta bancaria.</p>
    </div>

    <div>
      <p class="subtitle">Información que recopilamos de manera automática en el proceso de registro de la aplicación PRESTIX:</p>

      <p><b>Con el objetivo de validar tu identidad y darte acceso a servicios financieros, analizaremos esta información de manera complementaria a la que utilizan los proveedores financieros tradicionales.</b></p>

      <p>- Información de los dispositivos desde los que accedes a la aplicación de PRESTIX y otros datos capturados automáticamente (como el tipo del sistema operativo, marca y modelo del dispositivo móvil,  dirección IP, configuraciones, datos de conexiones, aplicaciones descargadas).</p>

      <p>- Lista de contactos de los dispositivos móviles para validar tu identidad, prevenir fraudes digitales y evaluar tu perfil crediticio te pediremos acceso a tu lista de contactos.</p>

      <p>- Reconoces que PRESTIX transmitirá los datos mencionados a través de una API tomando todas las medidas de seguridad para garantizar la privacidad de tus datos.</p>

      <p>- Información sobre tu ubicación (geolocalización), que es principalmente  utilizada para confirmar que te encontrás en el área de cobertura para ofrecerte servicios, entre otros.</p>
    </div>

    <div>
      <p class="subtitle">Información que recopilamos de otras fuentes</p>

      <p>- Información recopilada para finalidades de prevención de fraude y cumplimiento de regímenes informativos (listas PEPs, OFAC, etc.).</p>

      <p>- Información crediticia, positiva y negativa, que obtenemos de bases o centrales de riesgo crediticio, empresas de telecomunicaciones y/o de fuentes de acceso público, de conformidad con la legislación aplicable.</p>

      <p>- Datos que se utilizan para la validación de identidad, completar o corregir información, obtenidos de fuentes seguras y confiables, tales como organismos públicos, proveedores de servicios o aliados comerciales con los que trabajamos.</p>

      <p>El Usuario puede registrarse en la APP o sitio web PRESTIX con Google y Gmail sign in: https://www.google.com.ar/intl/es/policies/privacy/key-terms/#toc-terms-account. Google podrá proporcionar a PRESTIX información sobre el Usuario según lo definido en la política de Privacidad de Google. PRESTIX acepta y cumple con la Política de Privacidad definida por Google, a la que se puede acceder a través del siguiente enlace: https://www.google.com/intl/es_ar/policies/privacy/</p>

      <p>El Usuario puede registrarse en la App PRESTIX con Facebook sign in: https://www.facebook.com/about/privacy/your-info-on-other. Facebook podrá proporcionar a PRESTIX información sobre el usuario según lo definido en la Política de Privacidad de Facebook. </p>

      <p>La APP o sitio web PRESTIX cumple con la Política de Privacidad definida por Facebook, a la que se puede acceder a través del siguiente enlace: https://www.facebook.com/about/privacy/.</p>

      <p><b>En algunos casos, esta información podría ser considerada sensible de acuerdo a la legislación aplicable. En estos casos, solicitamos tu consentimiento expreso para procesarlos.</b></p>
    </div>

    <div>
      <p class="subtitle">¿Qué hacemos con la información personal?</p>

      <ul style="list-style-type:disc;">
        <p>La recolección y tratamiento de tu información personal nos permite brindarte un excelente servicio para que puedas realizar operaciones de forma rápida y segura y ofrecerte funcionalidades que se adaptan mejor a tus necesidades. Salvo en aquellos casos en los que la normativa aplicable lo prohíba, PRESTIX podrá utilizar tu información personal para las siguientes finalidades:</p>

        <li><p>Verificar tu identidad en cumplimiento de exigencias legales.</p></li>

        <li><p>Validar, actualizar y corregir tu información.</p></li>

        <li><p>Brindarte los servicios y/o beneficios que solicitás o contratás con nosotros (por ejemplo un crédito personal).</p></li>

        <li><p>Elaborar y mantener un registro de las operaciones que realicés, así como informarte acerca de las mismas y darle seguimiento correspondiente (por ejemplo el avance de pago de cuotas de tu préstamo).</p></li>

        <li><p>Atender tus comentarios, quejas y sugerencias, así como brindarte soporte.</p></li>

        <li><p>Consultar y reportar datos positivos y negativos en las centrales de riesgos crediticios, realizar labores de verificación de riesgo comercial y/o de crédito, analizar la viabilidad de celebrar o mantener una relación comercial y elaborar perfiles con fines de análisis crediticio.</p></li>

        <li><p>Elaborar perfiles con fines de análisis crediticio.</p></li>

        <li><p>Ofrecerte y gestionar los productos crediticios que solicites o contrates con nosotros.</p></li>

        <li><p>Cobranza judicial y/o extrajudicial.</p></li>

        <p><b>Además, en PRESTIX diseñamos nuevas formas de promover y ampliar el acceso a servicios financieros de calidad para vos.</b></p>

        <li><p>Elaborar perfiles mediante el análisis de diversas variables, como la conducta o las interacciones dentro de la plataforma, el análisis y predicción de la capacidad económica, preferencias, intereses, historial de créditos, comportamiento y ubicación, entre otros, para mejorar nuestras iniciativas de inclusión financiera.</p></li>
      </ul>
    </div>

    <div>
      <p class="subtitle">Cumplir con obligaciones legales y requerimientos de autoridades competentes.</p>

      <ul style="list-style-type:disc;">
        <li><p>Cumplimiento de normativa de Prevención de Lavado de Dinero y Financiamiento del Terrorismo (acciones de validación de identidad (KYC), verificación de identidad contra de Personas Expuestas Políticamente, verificación de perfil e historial transaccional, en cumplimiento de la regulación aplicable en materia de prevención de lavado de dinero, verificación contra listas OFAC y otras), según sea aplicable en cada país.</p></li>

        <li><p>Cumplimiento de regímenes informativos en general, según sea aplicable en cada país.</p></li>

        <li><p>Cumplimiento de regímenes fiscales de recaudación, registración, información, auditoría y facturación, a cualquier nivel de gobierno (por ejemplo: nivel federal, estadual y municipal).</p></li>

        <li><p>Cumplimiento de requerimientos informativos de autoridades administrativas o judiciales competentes.</p></li>
      </ul>
    </div>

    <div>
      <p class="subtitle">Detectar y prevenir fraudes, abusos y delitos relacionados para proteger la seguridad de nuestros usuarios y la sustentabilidad de las plataformas.</p>

      <ul style="list-style-type:disc;">
        <li><p>Hacer que las plataformas crezcan de una manera sustentable y segura mediante herramientas y acciones de prevención del fraude y delitos relacionados.</p></li>

        <li><p>Entrenar el modelo de algoritmo de detección y prevención automatizada del fraude.</p></li>
      </ul>
    </div>

    <div>
      <p class="subtitle">No compartimos la información personal</p>

      <p>El resguardo de tu privacidad es muy importante para PRESTIX.  Por ello, <b>no vendemos ni comercializamos información que identifique a nuestros usuarios. Tampoco compartimos o transferimos de ningún otro modo tu información personal a terceros.</b></p>
    </div>

    <div>
      <p class="subtitle">¿Por cuánto tiempo vamos a almacenar la información personal?</p>

      <p>Solo almacenaremos la información personal durante el tiempo necesario para cumplir con el propósito para el que se ha recopilado, para cumplir con requisitos reglamentarios o legales, o durante el periodo de prescripción legal de posibles responsabilidades legales o contractuales.</p>

      <p>Una vez concluido el lapso, los datos serán eliminados o anonimizados de manera tal que no pueda ser individualizada ninguna persona.</p>
    </div>

    <div>
      <p class="subtitle">Decisiones automatizadas y elaboración de perfiles</p>

      <p>PRESTIX diseña nuevos servicios para promover la inclusión financiera mediante la utilización de scoring alternativo y asistir a aquellas personas que no presentan o no calificarían al ser analizadas de manera tradicional.</p>

      <p>Para esto recurrimos a herramientas que nos ayudan a ser más eficientes mediante técnicas conocidas como “inteligencia artificial”, “machine learning” o “Big Data” que utilizamos para diversos fines, como prevención del fraude, análisis y predicción de la capacidad económica, mecanismos de ciberseguridad automatizados o validación de identidad mediante reconocimiento de imágenes.</p>

      <p>Las decisiones automatizadas son aquellas que se toman en base al uso de algoritmos y programas informáticos, sin que intervenga ningún ser humano en el proceso de la toma de decisión.</p>

      <p>Por su parte, la elaboración de perfiles es la evaluación de algunos aspectos personales, como comportamientos o ubicación, que se realiza procesando de manera automatizada información personal con procedimientos estadísticos.</p>
    </div>

    <div>
      <p class="subtitle">Cookies y Otras Tecnologías</p>

      <p>Reconocés y aceptás expresamente que PRESTIX podrá utilizar un sistema de seguimiento de conducta mediante la utilización de "cookies" y/u otras tecnologías similares de seguimiento.</p>

      <p>Tus datos personales obtenidos a través de estas tecnologías no serán transferidos a terceros.</p>

      <p>Tené en cuenta que la instalación, permanencia y existencia de las cookies en tu computadora o dispositivo depende de tu exclusiva voluntad y pueden ser eliminadas cuando así lo desees. Para saber cómo quitar las Cookies del sistema es necesario revisar la sección Ayuda (Help) del navegador.</p>
    </div>

    <div>
      <p class="subtitle">Tus derechos</p>

      <p>Tenés derecho a acceder, actualizar, corregir, eliminar o cancelar tu información personal y oponerte a que usemos tus datos. Podés comunicarte con nosotros a través de atencionalcliente@prestix.com.ar o a través de la línea gratuita de Atención al cliente de WhatsApp +549 11 6350 4807.</p>
    </div>

    <div>
      <p class="subtitle">Seguridad. Almacenamiento de la información personal</p>

      <p>Cumple con la normativa y ha adoptado medidas de seguridad siguiendo los estándares de la industria para proteger tu información personal.</p>

      <p>En la medida en que PRESTIX haya cumplido con las normas y adoptado las medidas mencionadas en el apartado anterior, no se hace responsable por interceptaciones ilegales o violaciones de sus sistemas o bases de datos, ni por su utilización por parte de personas no autorizadas. PRESTIX tampoco se hace responsable por la indebida utilización de la información obtenida por esos medios.</p>
    </div>

    <div>
      <p class="subtitle">Ley Aplicable y Jurisdicción</p>

      <p>La Declaración de Privacidad se regirá por las leyes aplicables conforme se detalla en los anexos de cada país incluidos debajo. Ante cualquier controversia o divergencia relacionada con la interpretación, validez, celebración o cumplimiento de la presente Declaración de Privacidad, se resolverá por los tribunales competentes indicados en dichos anexos de cada país incluidos debajo.</p>
    </div>

    <div>
      <p class="subtitle">ARGENTINA</p>

      <p class="subtitle">¿Quién es el responsable del tratamiento de la Información Personal?</p>

      <p>En Argentina, los Servicios de PRESTIX son prestados por Corvalán 1160 S.A., sociedad incorporada bajo las leyes de la Argentina, CUIT 30-71005426-2, con domicilio en Maipú 255, piso 5°, Ciudad Autónoma de Buenos Aires, C.P.: 1002. República Argentina; Atte.: Corvalán 1160 S.A.. Dichos servicios son ofrecidos a través de las Plataformas www.prester.com.ar y la App PRESTIX publicada en Google Play Store.</p>
    </div>

    <div>
      <p class="subtitle">¿Cómo podés ejercer tus derechos para controlar tu Información Personal?</p>

      <p>Sin perjuicio de lo indicado en esta Declaración de Privacidad, en cumplimiento de lo dispuesto por las disposiciones de la Ley N° 25.326, el Decreto Reglamentario N° 1558/2001 y las disposiciones y/o resoluciones vinculantes emitidas por la Agencia de Acceso a la Información Pública se comunica que: "el titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales. El titular podrá en cualquier momento solicitar el retiro o bloqueo de su nombre de los bancos de datos a los que se refiere el presente artículo. En toda comunicación con fines de publicidad que se realice por correo, teléfono, correo electrónico, Internet u otro medio a distancia a conocer, se deberá indicar, en forma expresa y destacada, la posibilidad del titular del dato de solicitar el retiro o bloqueo, total o parcial, de su nombre de la base de datos. A pedido del interesado, se deberá informar el nombre del responsable o usuario del banco de datos que proveyó la información."</p>

      <p class="end">Sin perjuicio de lo dispuesto en la Declaración de Privacidad, también podrás realizar consultas y/o ejercer los derechos de acceso, rectificación y supresión de tu Información Personal por correo postal a Maipú 255, piso 5°, Ciudad Autónoma de Buenos Aires, C.P.: 1002. Atte.: Corvalán 1160 S.A.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalPolicies",
  methods: {},
};
</script>

<style scoped>
p {
  margin: 0;
  color: #000000;
  font-size: 13pt;
  font-family: "CommonsRegular";
  text-align: left;
  text-align: justify;
  padding-bottom: 10px;
}

b {
  font-family: "CommonsBold";
}

.section {
  padding: 0 10%;
}

.subtitle {
  color: #000000 !important;
  font-size: 13pt;
  font-family: "CommonsBold";
  text-align: left;
  padding-top: 15px;
  text-indent: -30px;
}

.start {
  padding-top: 50px;
}

.end {
  padding-bottom: 50px;
}
</style>